import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../layouts'

const outlineWrap = css`
  display: flex;
  flex-wrap: wrap;
`

const logoImage = css`
  width: min(300px, 50%);
  
  @media (max-width:1000px) {
    width: 100%;
  }
`

const companyInfo = css`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  
  @media (max-width:1000px) {
    width: 100%;
  }
  
  & dt,
  & dd {
    border-bottom: solid 1px #ccc;
    
    padding: 16px 0;
    
    &:last-of-type {
      border-bottom: none;
    }
  }
  
  & dt {
    width: 20%;
  }
  
  & dd {
    width: 80%;
  }
  
  & ul {
    list-style-type: square;
    
    @media (max-width:1000px) {
      padding-left: 1em;
    }
  }
  
  & li {
    margin-bottom: 8px;
  }
`

const oreWrap = css`
  display: flex;
  flex-wrap: wrap;
`

const flexOre = css`
  display: flex;
  flex: 1;
  
  @media (max-width:1000px) {
    width: 100%;
  }
`

const authorImage = css`
  width: 100px;
  
  @media (max-width:1000px) {
    width: 50px;
  }
  
  & img {
    width: 100%;
    border-radius: 50%;
  }
`

const authorNameFoot = css`
  font-size: clamp(20px, 2.2vw, 30px);
  margin-bottom: 16px;
`

const snsLogos = css`
  display: flex;
  margin-bottom: 16px;
  
  > a {
    width: 40px;
    margin-right: 20px;
  }
`

const twitter = css`
  display: block;
`

const instagram = css`
  display: block;
`

const authorInfo = css`
  flex: 1;
  padding-left: 1em;
`

const oreKV = css`
  width: min(500px, 50%);
  padding-left: 1em;
  
  @media (max-width:1000px) {
    width: 100%;
  }
`

const About = ({ data }) => {
  return (
    <Layout>
      <section>
        <h1>{data.site.siteMetadata.title} 会社概要</h1>
        <div className={outlineWrap}>
          <div className={logoImage}>
            <StaticImage
              src="../images/common/logoText.svg" // 相対パス
              alt="WEB工房しずおかロゴ"
              placeholder="blurred" // ボヤッと読み込ませるオプション。他にもいくつかある
              quality="40" // 画質
            />
          </div>
          <dl className={companyInfo}>
            <dt>会社名</dt>
            <dd>合同会社WEB工房しずおか</dd>
            
            <dt>英語表記</dt>
            <dd>WEB SHIZUOKA LLC</dd>
            
            <dt>所在地</dt>
            <dd>〒424-0875 静岡県静岡市清水区馬走1番89号</dd>
            
            <dt>代表者</dt>
            <dd>小塩 二郎</dd>
            
            <dt>設立</dt>
            <dd>2017年9月</dd>
            
            <dt>事業内容</dt>
            <dd>
              <ul>
                <li>WEBシステムの企画・開発</li>
                <li>WEBサイトの企画・制作</li>
                <li>WordPressカスタマイズ全般</li>
                <li>Osclassカスタマイズ全般</li>
                <li>ブログ運用コンサルティング</li>
                <li>植物・盆栽の販売</li>
              </ul>
            </dd>
          </dl>
        </div>
        
        <div className={oreWrap}>
          
          <div className={flexOre}>
            <div className={authorImage}>
              <StaticImage
                src="../images/author/koshio.jpg"
                alt=""
                placeholder="blurred"
                quality="40"
              />
            </div>
            <div className={authorInfo}>
              <div className={authorNameFoot}>小塩 二郎</div>
              
              <p>
                1982年、静岡県清水市（現：静岡市清水区）生まれ。
              </p>
              
              <p>
                「効率的な農業」に興味があり、模索をしております。<br />
                植物で地球環境をよくすることが夢です。
              </p>
              
              <p>
                現在は、C/C++、Python、PHP、Javascript、HTML/CSS 等、 様々な言語で仕事をいただいております。<br />
                システムやWEBサイトをお客様と共に創ることが最高の喜びです。
              </p>
              
              <p>
                絵はとても下手ですが、描くことは好きです。<br />
                最近では絵の練習をしております。<br />
                本サイトにも、少しだけ私の絵が登場しております。<br />
                （一番下手なのが私の絵です。）
              </p>
              
              <div className={snsLogos}>
                <a className={twitter} href="https://twitter.com/Web_Shizuoka" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/common/twitter.svg"
                    alt="Shiva"
                    placeholder="blurred"
                    quality="40"
                  />
                </a>
                <a className={instagram} href="https://www.instagram.com/webshizuoka/" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/common/instagram.svg"
                    alt="Shiva"
                    placeholder="blurred"
                    quality="40"
                  />
                </a>
              </div>
            </div>
          </div>
          
          <div className={oreKV}>
            <StaticImage
              src="../images/about/oreKV.png"
              alt="Shiva"
              placeholder="blurred"
              quality="40"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default About
